export default {
  // account icons
  trashCanButton: 'trashcanButton_Icon',
  editButton: 'editButton_Icon',
  loginInfo: 'loginInfo_Icon',
  loginInfoImpersonating: 'loginInfoImpersonating_Icon',
  //menu icons
  menuOpen: 'menu_Open_Icon',
  menuClose: 'menu_Close_Icon',
  menuOpenItem: 'menuItem_Open_Icon',
  menuMobileOpenItem: 'menuItem_Mobile_Open_Icon',
  menuMobileCloseItem: 'menuItem_Mobile_Close_Icon',
  // shopping cart icons
  folderOpen: 'folderOpen_Icon',
  trashCan: 'trashcan_Icon',
  save: 'save_Icon',
  heart: 'heart_Icon',
  cartRefresh: 'cartRefresh_Icon',
  cartSummary: 'cartSummary_Icon',
  // product list page icons
  listView: 'listView_Icon',
  listViewPassive: 'listViewPassive_Icon',
  gridView: 'gridView_Icon',
  gridViewPassive: 'gridViewPassive_Icon',
  // other icons
  search: 'search_Icon',
  calendar: 'calendar_Icon',
  checked: 'checked_Icon',
  scrollToTop: 'scrollToTop_Icon',
  crossSmall: 'crossSmall_Icon',
  crossBig: 'crossBig_Icon',
  cartSummarySticky: 'cartSummarySticky_Icon',
  listItem: 'listItem_Icon',
  listSelectedItem: 'listSelectedItem_Icon',
  mediumArrowUp: 'mediumArrowUp_Icon',
  mediumArrowDown: 'mediumArrowDown_Icon',
  mediumArrowLeft: 'mediumArrowLeft_Icon',
  mediumArrowRight: 'mediumArrowRight_Icon',
  mediumDoubleArrowLeft: 'mediumDoubleArrowLeft_Icon',
  mediumDoubleArrowRight: 'mediumDoubleArrowRight_Icon',
  bigArrowLeft: 'bigArrowLeft_Icon',
  bigArrowLeftPassive: 'bigArrowLeftPassive_Icon',
  bigArrowRight: 'bigArrowRight_Icon',
  bigArrowRightPassive: 'bigArrowRightPassive_Icon',
  searchButton: 'searchButton_Icon',
  cartSuggestion: 'cartSuggestion_Icon',
  phone: 'phone_Icon',
  smallArrowLeft: 'smallArrowLeft_Icon',
  smallArrowRight: 'smallArrowRight_Icon',
  statusSuccess: 'statusSuccess_Icon',
  statusFailed: 'statusFailed_Icon',
  statusPending: 'statusPending_Icon',
  expand: 'expand_Icon',
  collapse: 'collapse_Icon',
  slideArrowDown: 'slideArrowDown_Icon',
  slideArrowRight: 'slideArrowRight_Icon',
};
